<template>
  <MainLayout
    :title="$t('application.title') + ' ' + procedureCode"
    :short-title="$t('application.title') + ' ' + procedureCode"
    back-to="/"
    has-sidebar
  >
    <template #header-right-mobile>
      <div v-if="isEntryLoaded">
        <b-button
          v-if="!canSubmit && !isSubmitted && !isCancelled && canEdit"
          type="is-text"
          @click="doSaveApplication"
        >
          {{ $t('actions.save') }}
        </b-button>
        <b-button v-if="canOpen" type="is-text" @click="doReopen">{{ $t('actions.reopen') }}</b-button>
        <b-dropdown v-if="canSubmit && canEdit" position="is-bottom-left" aria-role="menu">
          <b-button slot="trigger" class="has-margin-left" role="button">
            <span>{{ $t('options') }}</span>
          </b-button>
          <b-dropdown-item v-if="canSubmit && canEdit" aria-role="menuitem" @click="doSaveApplication">
            {{ $t('actions.submit') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canEdit" aria-role="menuitem" @click="doCancelApplication">
            {{ $t('applicationState.cancel') }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canOpen" aria-role="menuitem" @click="doReopen">
            {{ $t('applicationState.reopen') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>

    <template #menu>
      <div>
        <div class="menu">
          <div class="menu-label">{{ $t('sections') }}</div>
          <scrollactive :offset="70">
            <ul class="menu-list">
              <li>
                <a href="#s-personal-data" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-personal-data') && isDraft" class="active" icon="check-circle" />
                  {{ $t('personalData') }}
                </a>
              </li>
              <li>
                <a href="#s-cv" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-cv') && isDraft" class="active" icon="check-circle" />
                  {{ $t('cv.title') }}
                </a>
              </li>
              <li>
                <a href="#s-studies" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-studies') && isDraft" class="active" icon="check-circle" />
                  {{ $t('certificates.title') }}
                </a>
              </li>
              <li>
                <a href="#s-training" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-training') && isDraft" class="active" icon="check-circle" />
                  {{ $t('training.title') }}
                </a>
              </li>
              <li>
                <a href="#s-experience" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-experience') && isDraft" class="active" icon="check-circle" />
                  {{ $t('experience.title') }}
                </a>
              </li>
              <li>
                <a href="#s-other-roles" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-other-roles') && isDraft" class="active" icon="check-circle" />
                  {{ $t('otherRoles.title') }}</a
                >
              </li>
              <li>
                <a href="#s-public-employment" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-public-employment') && isDraft" class="active" icon="check-circle" />
                  {{ $t('legal.title') }}</a
                >
              </li>
              <li>
                <a href="#s-acceptance" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-acceptance') && isDraft" class="active" icon="check-circle" />
                  {{ $t('acceptance') }}
                </a>
              </li>
              <li>
                <a href="#s-handicap" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-handicap') && isDraft" class="active" icon="check-circle" />
                  {{ $t('handicap') }}
                </a>
              </li>
              <li>
                <a href="#s-additional-notes" class="scrollactive-item">
                  <faicon v-if="isSectionReady('s-additional-notes') && isDraft" class="active" icon="check-circle" />
                  {{ $t('additionalInformation') }}
                </a>
              </li>
            </ul>
          </scrollactive>
        </div>
      </div>
    </template>

    <template #content>
      <div v-if="isEntryLoaded" class="panel is-hidden-tablet">
        <b-tag
          v-if="!isSubmitted"
          size="is-medium"
          :type="isEntryLoaded && entry.application.status == 'cancelled' ? 'is-black' : 'is-warning'"
          >{{ applicationStatus }}</b-tag
        >
        <b-tag v-else size="is-medium" type="is-primary">{{ $t('applicationState.submitted') }}</b-tag>
      </div>

      <form id="s-personal-data" class="panel" @submit.prevent="doSaveApplication" @keyup.enter="doSaveApplication">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('personalData') }}
              <faicon v-if="isSectionReady('s-personal-data')" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field
            :label="$t('fullName')"
            :type="{ 'is-danger': $v.entry.application.name.$error }"
            :message="$v.entry.application.name.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.name" :disabled="!canEdit" size="is-medium" type="text" name="name" />
          </b-field>

          <b-field
            :label="$t('birthday')"
            :type="{ 'is-danger': $v.entry.application.birthday.$error }"
            :message="$v.entry.application.birthday.$error ? $t('required') : ''"
          >
            <b-datepicker
              v-model="entry.application.birthday"
              :disabled="!isDraft || !canEdit"
              :placeholder="$t('date')"
              class="is-width-medium"
              icon="calendar-plus"
            >
            </b-datepicker>
          </b-field>

          <b-field
            :label="$t('address')"
            :type="{ 'is-danger': $v.entry.application.address_line1.$error }"
            :message="$v.entry.application.address_line1.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.address_line1" :disabled="!canEdit" type="text" name="address_line1" />
          </b-field>
          <b-field :label="$t('address2')" class="is-marginless">
            <b-input
              v-model="entry.application.address_line2"
              :disabled="!canEdit"
              maxlength="200"
              type="text"
              name="address_line2"
            />
          </b-field>

          <b-field grouped class="is-marginless">
            <b-field
              :label="$t('postalCode')"
              :type="{ 'is-danger': $v.entry.application.postal_code.$error }"
              :message="$v.entry.application.postal_code.$error ? $t('required') : ''"
            >
              <b-input
                v-model="entry.application.postal_code"
                size="is-width-small"
                :disabled="!canEdit"
                maxlength="8"
                type="text"
                placeholder="0000-000"
                name="postal_code"
              />
            </b-field>
            <b-field
              :label="$t('locality')"
              expanded
              :type="{ 'is-danger': $v.entry.application.locality.$error }"
              :message="$v.entry.application.locality.$error ? $t('required') : ''"
            >
              <b-input v-model="entry.application.locality" :disabled="!canEdit" type="text" name="locality" />
            </b-field>
          </b-field>

          <b-field
            class="is-marginless"
            :label="$t('country')"
            :type="{ 'is-danger': $v.entry.application.country.$error }"
            :message="$v.entry.application.phone_country.$error ? $t('required') : ''"
          >
            <CountriesSelect :disabled="!canEdit" :input="entry.application.country" @update="updateCountry" />
          </b-field>

          <b-field class="has-margin-top-medium" grouped>
            <b-field
              :label="$t('phoneCountry')"
              :type="{ 'is-danger': $v.entry.application.phone_country.$error }"
              :message="$v.entry.application.phone_country.$error ? $t('required') : ''"
            >
              <PhoneCountriesSelect
                :disabled="!canEdit"
                :input="entry.application.phone_country"
                @update="updatePhoneCountry"
              />
            </b-field>

            <b-field
              :label="$t('phone')"
              expanded
              :type="{ 'is-danger': $v.entry.application.phone_number.$error }"
              :message="$v.entry.application.phone_number.$error ? $t('required') + '(' + $t('onlyNumber') + ')' : ''"
            >
              <b-input
                v-model="entry.application.phone_number"
                :disabled="!canEdit"
                type="text"
                maxlength="14"
                name="phone_number"
              />
            </b-field>
          </b-field>

          <b-field
            class="is-marginless"
            :label="$t('email')"
            :type="{ 'is-danger': $v.entry.application.email.$error }"
            :message="$v.entry.application.email.$error ? $t('required') : ''"
          >
            <b-input v-model="entry.application.email" type="email" name="email" />
          </b-field>

          <h4 class="has-margin-top has-margin-bottom">{{ $t('id.label') }}</h4>

          <b-field
            class="is-width-small"
            :label="$t('nif')"
            :type="{ 'is-danger': $v.entry.application.nif.$error }"
            :message="$v.entry.application.nif.$error ? $t('required') : ''"
          >
            <b-input
              v-model="entry.application.nif"
              size="is-width-medium"
              :disabled="!canEdit"
              type="text"
              name="fiscal_number"
            />
          </b-field>
        </div>
      </form>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-cv" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('cv.title') }}
              <faicon v-if="isSectionReady('s-cv')" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <div v-if="canEdit && !getFilesByType('cv').length" v-html="$t('cv.files')"></div>
          <div class="files has-margin-top has-margin-bottom-medium">
            <div v-if="!getFilesByType('cv').length" class="instructions">
              {{ $t('noSectionDocuments') }}
            </div>
            <RecordFileEntry
              v-for="file in getFilesByType('cv')"
              :key="file.id"
              :can-edit="canEdit"
              :allow-classified="false"
              :file="file"
              @remove="removeFile"
              @download="downloadFile"
            />
          </div>
          <Uploader
            v-if="canEdit && !isSectionReady('s-cv')"
            reference-id="uploadCv"
            allowed="pdf"
            :clear-after="true"
            :limit="1"
            :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/cv'"
            @upload="updateFilesCart"
          />
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-studies" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('certificates.title') }}
              <faicon v-if="isSectionReady('s-studies')" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
          <div class="panel-header-nav"></div>
        </div>

        <div v-if="entry.application" class="panel-body">
          <div v-if="!withCertificates" class="instructions">
            {{ $t('entry.empty') }}
          </div>
          <div v-else class="has-margin-bottom">
            <div
              v-for="record in entry.application.certificates"
              :key="record.$index"
              class="record has-background-light has-padding-small"
            >
              <div class="is-flex-grow">
                <div class="is-flex is-flex-align-center is-flex-space-between">
                  <div class="record-title is-size-4 is-flex-grow">
                    {{ $t(`certificates.options.${record.type}`) }}
                  </div>
                  <div v-if="canEdit" class="record-nav">
                    <b-button
                      size="is-small"
                      type="is-danger"
                      outlined
                      @click="removeListEntry(record.key, 'certificates')"
                    >
                      {{ $t('actions.delete') }}
                    </b-button>
                  </div>
                </div>
                <div v-if="CERTIFICATES_WITH_EXTRA_FIELDS.has(record.type)">
                  <div class="has-text-small">{{ record.description }}</div>
                  <div class="has-text-small has-text-weight-bold">{{ record.school }}</div>
                  <div class="has-text-small">{{ record.end_date }} {{ record.score }}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="addStudiesActive && canEdit"
            class="has-background-light has-padding-medium is-rounded has-margin-bottom"
          >
            <h3 class="has-text-primary has-margin-bottom">{{ $t('entry.new') }}</h3>
            <b-field :label="$t('certificates.level')" :message="newStudiesEntry.$errors.type">
              <b-select v-model="newStudiesEntry.type" name="type" :disabled="!isDraft || !canEdit" expanded>
                <option v-for="type_key in CERTIFICATES" :key="type_key" :value="type_key">
                  {{ $t(`certificates.options.${type_key}`) }}
                </option>
              </b-select>
            </b-field>
            <div v-if="newStudiesEntry && CERTIFICATES_WITH_EXTRA_FIELDS.has(newStudiesEntry.type)">
              <b-field
                :label="$t('courseId')"
                expanded
                :type="{ 'is-danger': newStudiesEntry.$errors.description }"
                :message="newStudiesEntry.$errors.description"
              >
                <b-input v-model="newStudiesEntry.description" :disabled="!canEdit" type="text" name="description" />
              </b-field>
              <b-field
                :label="$t('dates.done')"
                :type="{ 'is-danger': newStudiesEntry.$errors.end_date }"
                :message="newStudiesEntry.$errors.end_date"
              >
                <b-datepicker
                  v-model="newStudiesEntry.end_date"
                  name="end_date"
                  :placeholder="$t('dates.done')"
                  class="is-width-medium"
                  icon="calendar-plus"
                >
                </b-datepicker>
              </b-field>
              <b-field
                :label="$t('finalGrade')"
                :type="{ 'is-danger': newStudiesEntry.$errors.score }"
                :message="newStudiesEntry.$errors.score"
              >
                <b-input v-model="newStudiesEntry.score" type="text" name="score" class="is-width-small" />
              </b-field>
              <b-field
                :label="$t('educationInstitution')"
                :type="{ 'is-danger': newStudiesEntry.$errors.school }"
                :message="newStudiesEntry.$errors.school"
              >
                <b-input v-model="newStudiesEntry.school" type="text" name="school" />
              </b-field>
            </div>

            <div class="is-flex is-flex-justify-right has-margin-top">
              <b-button type="is-light" class="has-margin-right" @click="addStudiesActive = false">
                {{ $t('actions.cancel') }}
              </b-button>
              <b-button type="is-primary" :loading="loadingNewItem" outlined @click="addStudies">
                {{ $t('actions.add') }}
              </b-button>
            </div>
          </div>
          <div v-if="!addStudiesActive && canEdit" class="has-margin-top has-margin-bottom-medium">
            <b-button type="is-text" outlined icon-left="plus" @click="addStudiesActive = true">
              {{ $t('entry.add') }}
            </b-button>
          </div>
        </div>

        <div v-if="getFilesByType('certificate').length || withCertificates">
          <h4>{{ $t('evidenceCopies') }}</h4>
          <div v-if="!loading" class="panel-body">
            <div v-if="isDraft && canEdit" v-html="$t('certificates.files')"></div>

            <div class="files has-margin-top has-margin-bottom-medium">
              <div v-if="!getFilesByType('certificate').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </div>
              <RecordFileEntry
                v-for="file in getFilesByType('certificate')"
                :key="file.id"
                :file="file"
                :allow-classified="false"
                :can-edit="canEdit"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>

            <Uploader
              v-if="canEdit"
              reference-id="uploadCertificate"
              allowed="pdf"
              :clear-after="true"
              :limit="0"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/certificate'"
              @upload="updateFilesCart"
            />
          </div>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-training" class="panel">
        <div class="panel-header">
          <div class="panel-header-title is-flex is-flex-align-center is-flex-space-between">
            <h2>
              {{ $t('training.title') }}
              <faicon v-if="isSectionReady('s-training')" class="active" icon="check-circle" size="xs" />
            </h2>
            <b-tag size="is-small">{{ $t('optional') }}</b-tag>
          </div>
          <div class="panel-header-nav"></div>
        </div>

        <div v-if="entry.application" class="panel-body">
          <div v-if="!withProfessionalQualifications" class="instructions">
            {{ $t('entry.empty') }}
          </div>
          <div v-else class="has-margin-bottom">
            <div
              v-for="record in entry.application.professional_qualifications"
              :key="record.$index"
              class="record has-background-light has-padding-small"
            >
              <div class="record-content is-paddingless">
                <div class="record-title">{{ record.description }}</div>
                <div class="has-text-small has-text-weight-bold">{{ record.company }}</div>
                <div class="has-text-small is-flex">
                  <div>{{ record.start_date | formatDate }}</div>
                  <div v-if="record.done_date" class="has-margin-left">/ {{ record.done_date | formatDate }}</div>
                </div>
                <div class="has-text-small is-flex">
                  <div>{{ record.hours }}h</div>
                  <div v-if="record.score" class="has-margin-left">/ {{ record.score }}</div>
                </div>
              </div>
              <div v-if="canEdit" class="record-nav">
                <b-button
                  size="is-small"
                  type="is-danger"
                  outlined
                  @click="removeListEntry(record.key, 'professional_qualifications')"
                >
                  {{ $t('actions.delete') }}
                </b-button>
              </div>
            </div>
          </div>
          <div
            v-if="addTrainingActive && canEdit"
            class="has-background-light has-padding-medium is-rounded has-margin-bottom"
          >
            <h3 class="has-text-primary has-margin-bottom">{{ $t('entry.new') }}</h3>
            <b-field
              :label="$t('training.description')"
              expanded
              :type="{ 'is-danger': newTrainingEntry.$errors.description }"
              :message="newTrainingEntry.$errors.description"
            >
              <b-input v-model="newTrainingEntry.description" type="text" name="description" />
            </b-field>
            <b-field
              :label="$t('training.company')"
              :type="{ 'is-danger': newTrainingEntry.$errors.company }"
              :message="newTrainingEntry.$errors.company"
            >
              <b-input v-model="newTrainingEntry.company" type="text" name="company" />
            </b-field>
            <b-field
              :label="$t('dates.start')"
              :type="{ 'is-danger': newTrainingEntry.$errors.start_date }"
              :message="newTrainingEntry.$errors.start_date"
            >
              <b-datepicker
                v-model="newTrainingEntry.start_date"
                :placeholder="$t('dates.start')"
                class="is-width-medium"
                icon="calendar-plus"
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="$t('dates.end') + '(' + $t('optional') + ')'"
              :type="{ 'is-danger': newTrainingEntry.$errors.end_date }"
              :message="newTrainingEntry.$errors.end_date"
            >
              <b-datepicker
                v-model="newTrainingEntry.end_date"
                :placeholder="$t('dates.done')"
                class="is-width-medium"
                icon="calendar-plus"
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="$t('training.hours')"
              :type="{ 'is-danger': newTrainingEntry.$errors.hours }"
              :message="newTrainingEntry.$errors.hours"
            >
              <b-input v-model="newTrainingEntry.hours" type="number" name="hours" class="is-width-small" />
            </b-field>
            <b-field :label="$t('training.score') + '(' + $t('ifApplicable') + ', ' + $t('optional') + ')'">
              <b-input v-model="newTrainingEntry.score" type="text" name="score" class="is-width-medium" />
            </b-field>
            <div class="is-flex is-flex-justify-right has-margin-top">
              <b-button type="is-light" class="has-margin-right" @click="addTrainingActive = false">
                {{ $t('actions.cancel') }}
              </b-button>
              <b-button type="is-primary" :loading="loadingNewItem" outlined @click="addTraining">
                {{ $t('actions.add') }}
              </b-button>
            </div>
          </div>
          <div v-if="!addTrainingActive && canEdit" class="has-margin-top has-margin-bottom-medium">
            <b-button type="is-text" outlined icon-left="plus" @click="addTrainingActive = true">
              {{ $t('entry.add') }}
            </b-button>
          </div>
        </div>

        <div v-if="getFilesByType('professional_qualification').length || withProfessionalQualifications">
          <h4>{{ $t('evidenceCopies') }}</h4>
          <div v-if="!loading" class="panel-body">
            <div v-if="isDraft && canEdit" v-html="$t('training.files')"></div>

            <div class="files has-margin-top has-margin-bottom-medium">
              <div v-if="!getFilesByType('professional_qualification').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </div>
              <RecordFileEntry
                v-for="file in getFilesByType('professional_qualification')"
                :key="file.id"
                :file="file"
                :allow-classified="false"
                :can-edit="canEdit"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>
            <Uploader
              v-if="canEdit"
              reference-id="uploadProfessionalQualification"
              allowed="pdf"
              :clear-after="true"
              :limit="0"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/professional_qualification'"
              @upload="updateFilesCart"
            />
          </div>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-experience" class="panel">
        <div class="panel-header">
          <div class="panel-header-title is-flex is-flex-align-center is-flex-space-between">
            <h2>
              {{ $t('experience.title') }}
              <faicon v-if="isSectionReady('s-experience')" class="active" icon="check-circle" size="xs" />
            </h2>
            <b-tag size="is-small">{{ $t('optional') }}</b-tag>
          </div>
          <div class="panel-header-nav"></div>
        </div>

        <div v-if="entry.application" class="panel-body">
          <div v-if="!withProfessionalExperiences" class="instructions">
            {{ $t('entry.empty') }}
          </div>
          <div v-else class="has-margin-bottom">
            <div
              v-for="record in entry.application.professional_experiences"
              :key="record.$index"
              class="record has-background-light has-padding-small"
            >
              <div class="record-content is-paddingless">
                <div class="record-title">{{ record.description }}</div>
                <div class="has-text-small has-text-weight-bold">{{ record.company }}</div>
                <div class="has-text-small is-flex">
                  <div>{{ record.start_date | formatDate }}</div>
                  <div v-if="record.end_date" class="has-margin-left">/ {{ record.end_date | formatDate }}</div>
                </div>
              </div>
              <div v-if="canEdit" class="record-nav">
                <b-button
                  size="is-small"
                  type="is-danger"
                  outlined
                  @click="removeListEntry(record.key, 'professional_experiences')"
                >
                  {{ $t('actions.delete') }}
                </b-button>
              </div>
            </div>
          </div>
          <div
            v-if="addExperienceActive && canEdit"
            class="has-background-light has-padding-medium is-rounded has-margin-bottom"
          >
            <h3 class="has-text-primary has-margin-bottom">{{ $t('entry.new') }}</h3>
            <b-field
              :label="$t('experience.description')"
              expanded
              :type="{ 'is-danger': newExperienceEntry.$errors.description }"
              :message="newExperienceEntry.$errors.description"
            >
              <b-input v-model="newExperienceEntry.description" type="text" name="description" />
            </b-field>
            <b-field
              :label="$t('experience.company')"
              :type="{ 'is-danger': newExperienceEntry.$errors.company }"
              :message="newExperienceEntry.$errors.company"
            >
              <b-input v-model="newExperienceEntry.company" type="text" name="company" />
            </b-field>
            <b-field
              :label="$t('dates.start')"
              :type="{ 'is-danger': newExperienceEntry.$errors.start_date }"
              :message="newExperienceEntry.$errors.start_date"
            >
              <b-datepicker
                v-model="newExperienceEntry.start_date"
                :placeholder="$t('dates.done')"
                class="is-width-medium"
                icon="calendar-plus"
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="$t('dates.end') + '(' + $t('optional') + ')'"
              :type="{ 'is-danger': newExperienceEntry.$errors.end_date }"
              :message="newExperienceEntry.$errors.end_date"
            >
              <b-datepicker
                v-model="newExperienceEntry.end_date"
                :placeholder="$t('dates.done')"
                class="is-width-medium"
                icon="calendar-plus"
              >
              </b-datepicker>
            </b-field>
            <div class="is-flex is-flex-justify-right has-margin-top">
              <b-button type="is-light" class="has-margin-right" @click="addExperienceActive = false">
                {{ $t('actions.cancel') }}
              </b-button>
              <b-button type="is-primary" :loading="loadingNewItem" outlined @click="addExperience">
                {{ $t('actions.add') }}
              </b-button>
            </div>
          </div>
          <div v-if="!addExperienceActive && canEdit" class="has-margin-top has-margin-bottom-medium">
            <b-button type="is-text" outlined icon-left="plus" @click="addExperienceActive = true">
              {{ $t('entry.add') }}
            </b-button>
          </div>
        </div>

        <div v-if="getFilesByType('professional_experience').length || withProfessionalExperiences">
          <h4>{{ $t('evidenceCopies') }}</h4>
          <div v-if="!loading" class="panel-body">
            <div v-if="isDraft && canEdit" v-html="$t('experience.files')"></div>

            <div class="files has-margin-top has-margin-bottom-medium">
              <div v-if="!getFilesByType('professional_experience').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </div>
              <RecordFileEntry
                v-for="file in getFilesByType('professional_experience')"
                :key="file.id"
                :file="file"
                :allow-classified="false"
                :can-edit="canEdit"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>
            <Uploader
              v-if="canEdit"
              reference-id="uploadProfessionalExperience"
              allowed="pdf"
              :clear-after="true"
              :limit="0"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/professional_experience'"
              @upload="updateFilesCart"
            />
          </div>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-other-roles" class="panel">
        <div class="panel-header">
          <div class="panel-header-title is-flex is-flex-align-center is-flex-space-between">
            <h2>
              {{ $t('otherRoles.title') }}
              <faicon v-if="isSectionReady('s-other-roles')" class="active" icon="check-circle" size="xs" />
            </h2>
            <b-tag size="is-small">{{ $t('optional') }}</b-tag>
          </div>
        </div>

        <div v-if="entry.application" class="panel-body">
          <div v-if="!withOtherActivitiesPerformed" class="instructions">
            {{ $t('entry.empty') }}
          </div>
          <div v-else class="has-margin-bottom">
            <div
              v-for="record in entry.application.other_activities_performed"
              :key="record.$index"
              class="record has-background-light has-padding-small"
            >
              <div class="record-content is-paddingless">
                <div class="record-title">{{ record.description }}</div>
                <div class="has-text-small has-text-weight-bold">{{ record.company }}</div>
                <div class="has-text-small is-flex">
                  <div>{{ record.start_date | formatMonthDate }}</div>
                  <div v-if="record.end_date" class="has-margin-left">/ {{ record.end_date | formatMonthDate }}</div>
                </div>
              </div>
              <div v-if="canEdit" class="record-nav">
                <b-button
                  size="is-small"
                  type="is-danger"
                  outlined
                  @click="removeListEntry(record.key, 'other_activities_performed')"
                >
                  {{ $t('actions.delete') }}
                </b-button>
              </div>
            </div>
          </div>
          <div
            v-if="addOtherRolesActive && canEdit"
            class="has-background-light has-padding-medium is-rounded has-margin-bottom"
          >
            <h3 class="has-text-primary has-margin-bottom">{{ $t('entry.new') }}</h3>
            <b-field
              :label="$t('otherRoles.description')"
              expanded
              :type="{ 'is-danger': newOtherRoleEntry.$errors.description }"
              :message="newOtherRoleEntry.$errors.description"
            >
              <b-input v-model="newOtherRoleEntry.description" type="text" name="description" />
            </b-field>
            <b-field
              :label="$t('otherRoles.company')"
              :type="{ 'is-danger': newOtherRoleEntry.$errors.company }"
              :message="newOtherRoleEntry.$errors.company"
            >
              <b-input v-model="newOtherRoleEntry.company" type="text" name="company" />
            </b-field>
            <b-field
              :label="$t('dates.start')"
              :type="{ 'is-danger': newOtherRoleEntry.$errors.start_date }"
              :message="newOtherRoleEntry.$errors.start_date"
            >
              <vue-monthly-picker
                v-model="newOtherRoleEntry.start_date"
                :placeholder="$t('dates.start') + '(' + $t('yearMonth') + ')'"
              ></vue-monthly-picker>
            </b-field>
            <b-field
              :label="$t('dates.end') + '(' + $t('optional') + ', ' + $t('yearMonth') + ')'"
              :type="{ 'is-danger': newOtherRoleEntry.$errors.end_date }"
              :message="newOtherRoleEntry.$errors.end_date"
            >
              <vue-monthly-picker
                v-model="newOtherRoleEntry.end_date"
                :placeholder="$t('dates.done') + '(' + $t('yearMonth') + ')'"
              ></vue-monthly-picker>
            </b-field>
            <div class="is-flex is-flex-justify-right has-margin-top">
              <b-button type="is-light" class="has-margin-right" @click="addOtherRolesActive = false">
                {{ $t('actions.cancel') }}
              </b-button>
              <b-button type="is-primary" :loading="loadingNewItem" outlined @click="addOtherRole">
                {{ $t('actions.add') }}
              </b-button>
            </div>
          </div>
          <div v-if="!addOtherRolesActive && canEdit" class="has-margin-top has-margin-bottom-medium">
            <b-button type="is-text" outlined icon-left="plus" @click="addOtherRolesActive = true">
              {{ $t('entry.add') }}
            </b-button>
          </div>
        </div>

        <div v-if="getFilesByType('other_activities_performed').length || withOtherActivitiesPerformed">
          <h4>{{ $t('evidenceCopies') }}</h4>
          <div v-if="!loading" class="panel-body">
            <div v-if="isDraft && canEdit" v-html="$t('otherRoles.files')"></div>

            <div class="files has-margin-top has-margin-bottom-medium">
              <div v-if="!getFilesByType('other_activities_performed').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </div>
              <RecordFileEntry
                v-for="file in getFilesByType('other_activities_performed')"
                :key="file.id"
                :file="file"
                :allow-classified="false"
                :can-edit="canEdit"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>
            <Uploader
              v-if="canEdit"
              reference-id="uploadOtherActivitiesPerformed"
              allowed="pdf"
              :clear-after="true"
              :limit="0"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/other_activities_performed'"
              @upload="updateFilesCart"
            />
          </div>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-public-employment" class="panel">
        <div class="panel-header">
          <div class="panel-header-title">
            <h2>
              {{ $t('legal.title') }}
              <faicon v-if="isSectionReady('s-public-employment')" class="active" icon="check-circle" size="xs" />
            </h2>
          </div>
        </div>

        <div v-if="entry.application" class="panel-body">
          <div>
            <b-field :label="$t('legal.holder')" expanded>
              <b-select
                v-model="entry.application.with_legal_functional"
                size="is-medium"
                :disabled="!isDraft || !canEdit"
              >
                <option :value="false">{{ $t('no') }}</option>
                <option :value="true">{{ $t('yes') }}</option>
              </b-select>
            </b-field>
          </div>
          <div v-if="entry.application.with_legal_functional" class="has-margin-top">
            <b-field :label="$t('legal.company')">
              <b-input v-model="entry.application.legal_company" :disabled="!isDraft || !canEdit" type="text" />
            </b-field>
            <b-field :label="$t('legal.category')" expanded>
              <b-select v-model="entry.application.legal_category" :disabled="!isDraft || !canEdit">
                <option v-for="value in LEGAL_CATEGORIES" :key="value" :value="value">
                  {{ $t(`legal.categories.${value}`) }}
                </option>
              </b-select>
            </b-field>
            <div class="has-background-light has-padding-medium has-margin-bottom">
              <div class="label" v-html="$t('onlyOneOption')"></div>
              <b-field :label="$t('legal.appointment')" class="has-margin-top-medium">
                <b-select v-model="entry.application.legal_appointment" :disabled="!isDraft || !canEdit">
                  <option value=""></option>
                  <option v-for="value in LEGAL_APPOINTMENTS" :key="value" :value="value">
                    {{ $t(`legal.appointments.${value}`) }}
                  </option>
                </b-select>
              </b-field>
              <b-field :label="$t('legal.contract')">
                <b-select v-model="entry.application.legal_contract" :disabled="!isDraft || !canEdit">
                  <option value=""></option>
                  <option v-for="value in LEGAL_CONTRACTS" :key="value" :value="value">
                    {{ $t(`legal.contracts.${value}`) }}
                  </option>
                </b-select>
              </b-field>
              <b-field grouped>
                <b-field :label="$t('legal.currentSituation')">
                  <b-select v-model="entry.application.legal_current_situation" :disabled="!isDraft || !canEdit">
                    <option value=""></option>
                    <option v-for="value in LEGAL_CURRENT_SITUATION" :key="value" :value="value">
                      {{ $t(`legal.currentSituations.${value}`) }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
            </div>
            <b-field :label="$t('legal.activity')">
              <b-input
                v-model="entry.application.legal_activity"
                type="text"
                name="other"
                :disabled="!isDraft || !canEdit"
              />
            </b-field>
            <b-field grouped>
              <b-field :label="$t('legal.evaluation')">
                <b-select v-model="entry.application.legal_evaluation" :disabled="!isDraft || !canEdit">
                  <option v-for="value in LEGAL_EVALUATION" :key="value" :value="value">
                    {{ $t(`legal.evaluations.${value}`) }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <div
              v-if="entry.application.legal_evaluation == 'yes'"
              class="has-background-light has-padding-small has-margin-top"
            >
              <h4 class="has-margin-bottom">{{ $t('legal.evaluation3years') }}</h4>
              <b-field grouped>
                <b-field :label="$t('legal.evaluation1')" expanded>
                  <b-input
                    v-model="entry.application.legal_last_evaluation"
                    :disabled="!isDraft || !canEdit"
                    type="text"
                    name="other"
                  />
                </b-field>
                <b-field :label="$t('legal.score')">
                  <b-numberinput
                    v-model="entry.application.legal_last_evaluation_score"
                    :disabled="!isDraft || !canEdit"
                    step="0.001"
                    max="5"
                    min="0"
                  ></b-numberinput>
                </b-field>
              </b-field>
              <b-field grouped>
                <b-field :label="$t('legal.evaluation2') + '(' + $t('optional') + ')'" expanded>
                  <b-input
                    v-model="entry.application.legal_2_evaluation"
                    type="text"
                    name="other"
                    :disabled="!isDraft || !canEdit"
                  />
                </b-field>
                <b-field :label="$t('legal.score')">
                  <b-numberinput
                    v-model="entry.application.legal_2_evaluation_score"
                    :disabled="!isDraft || !canEdit"
                    step="0.001"
                    max="5"
                    min="0"
                  ></b-numberinput>
                </b-field>
              </b-field>
              <b-field grouped>
                <b-field :label="$t('legal.evaluation3') + '(' + $t('optional') + ')'" expanded>
                  <b-input
                    v-model="entry.application.legal_3_evaluation"
                    :disabled="!isDraft || !canEdit"
                    type="text"
                    name="other"
                  />
                </b-field>
                <b-field :label="$t('legal.score')">
                  <b-numberinput
                    v-model="entry.application.legal_3_evaluation_score"
                    :disabled="!isDraft || !canEdit"
                    step="0.001"
                    max="5"
                    min="0"
                  ></b-numberinput>
                </b-field>
              </b-field>
            </div>
            <div v-if="entry.procedure.term_type === 'indeterminate'" class="has-margin-top">
              <h4>{{ $t('legal.acceptedHeader') }}</h4>
              <div class="has-margin-top-small">{{ $t('legal.acceptedTitle') }}</div>
              <b-field class="has-padding">
                <b-checkbox v-model="entry.application.legal_accepted" :disabled="!isDraft || !canEdit">
                  {{ $t('legal.acceptedDescription') }}
                </b-checkbox>
              </b-field>
            </div>
          </div>
        </div>

        <div v-if="entry.application && entry.application.with_legal_functional" class="has-margin-top-medium">
          <h4>{{ $t('evidenceCopies') }}</h4>
          <div v-if="!loading" class="panel-body">
            <b-field class="has-padding">
              <b-checkbox v-model="entry.application.legal_is_uc_worker" :disabled="!isDraft || !canEdit">
                {{ $t('legal.isUCWorker') }}
              </b-checkbox>
            </b-field>

            <div v-if="!entry.application.legal_is_uc_worker">
              <div v-if="isDraft && canEdit" v-html="$t('legal.htmlIsUCWorkerHeader')"></div>
              <div class="files has-margin-top has-margin-bottom-medium">
                <div v-if="!getFilesByType('legal_functional').length" class="instructions">
                  {{ $t('noSectionDocuments') }}
                </div>
                <RecordFileEntry
                  v-for="file in getFilesByType('legal_functional')"
                  :key="file.id"
                  :file="file"
                  :allow-classified="false"
                  :can-edit="canEdit"
                  @remove="removeFile"
                  @download="downloadFile"
                />
              </div>
              <Uploader
                v-if="canEdit"
                reference-id="uploadLegalFunctional"
                allowed="pdf"
                :clear-after="true"
                :limit="0"
                :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/legal_functional'"
                @upload="updateFilesCart"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-acceptance" class="panel">
        <div class="panel-header">
          <h2>
            {{ $t('acceptance') }}
            <faicon v-if="isSectionReady('s-acceptance')" class="active" icon="check-circle" size="xs" />
          </h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_requirements"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_requirements')"
            >
              {{ $t('acceptanceRequirements') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.acceptance_documents_authenticity"
              :disabled="!canEdit"
              @input="updateSwitch('acceptance_documents_authenticity')"
            >
              {{ $t('acceptanceDocumentsAuthenticity') }}
            </b-switch>
          </b-field>
          <b-field>
            <b-switch
              v-model="entry.application.accept_notifications"
              :disabled="!canEdit"
              @input="doSaveApplication(true)"
            >
              {{ $t('acceptNotifications') }}
            </b-switch>
          </b-field>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-handicap" class="panel">
        <div class="panel-header">
          <h2>{{ $t('handicap') }}</h2>
        </div>
        <div v-if="loading" class="panel-body">
          <ContentLoader />
        </div>
        <div v-else class="panel-body">
          <b-field>
            <b-switch v-model="entry.application.is_handycap" :disabled="!canEdit" @input="updateSwitch('is_handycap')">
              {{ $t('isHandicap') }}
            </b-switch>
          </b-field>
          <div v-if="entry.application.is_handycap && canEdit" class="has-margin-top-medium">
            <h4>{{ $t('evidenceCopy') }}</h4>
            <div v-if="!loading" class="panel-body">
              <div v-if="isDraft && canEdit" v-html="$t('isHandicapFiles')"></div>
              <div class="files has-margin-top has-margin-bottom-medium">
                <div v-if="!getFilesByType('handicap').length" class="instructions">
                  {{ $t('noSectionDocuments') }}
                </div>
                <RecordFileEntry
                  v-for="file in getFilesByType('handicap')"
                  :key="file.id"
                  :file="file"
                  :allow-classified="false"
                  :can-edit="canEdit"
                  @remove="removeFile"
                  @download="downloadFile"
                />
              </div>
              <Uploader
                v-if="canEdit"
                reference-id="uploadHandicap"
                allowed="pdf"
                :clear-after="true"
                :limit="0"
                :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/handicap'"
                @upload="updateFilesCart"
              />
            </div>
          </div>
        </div>
      </div>

      <hr class="has-margin-top-large has-margin-bottom-large" />

      <div id="s-additional-notes" class="panel">
        <div class="panel-header">
          <h2>{{ $t('additionalInformation') }}</h2>
          <b-tag size="is-small">{{ $t('optional') }}</b-tag>
        </div>
        <div class="panel-content">
          <b-field v-if="isDraft" :label="$t('otherInformation')" :message="$t('atLeast50Chars')">
            <div class="html-editor">
              <editor-menu-bar v-if="isDraft && canEdit" v-slot="{ commands, isActive, focused }" :editor="editor">
                <div class="menubar" :class="{ 'is-focused': focused }">
                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.bold() }"
                    @click="commands.bold"
                  >
                    <icon name="bold" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.italic() }"
                    @click="commands.italic"
                  >
                    <icon name="italic" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.underline() }"
                    @click="commands.underline"
                  >
                    <icon name="underline" />
                  </button>

                  <button
                    class="button is-text is-small"
                    :class="{ 'is-active': isActive.paragraph() }"
                    @click="commands.paragraph"
                  >
                    <icon name="paragraph" />
                  </button>

                  <button class="button is-text is-small" @click="commands.undo">
                    <icon name="undo" />
                  </button>

                  <button class="button is-text is-small" @click="commands.redo">
                    <icon name="redo" />
                  </button>
                </div>
              </editor-menu-bar>
              <editor-content :editor="editor" />
            </div>
          </b-field>
          <div v-if="!isDraft && isEntryLoaded" class="has-margin-top">
            <div
              v-if="entry.application.user_notes"
              class="content box has-padding"
              v-html="entry.application.user_notes"
            ></div>
            <div v-else class="instructions">{{ $t('noContent') }}</div>
          </div>
          <div class="has-margin-top-medium">
            <h4 class="is-size-4">{{ $t('extraFiles') }}</h4>
            <div v-if="isDraft && canEdit" v-html="$t('userNotesFiles')"></div>
            <div class="files has-margin-top has-margin-bottom-medium">
              <div v-if="!getFilesByType('other').length" class="instructions">
                {{ $t('noSectionDocuments') }}
              </div>
              <RecordFileEntry
                v-for="file in getFilesByType('other')"
                :key="file.id"
                :file="file"
                :can-edit="canEdit"
                @remove="removeFile"
                @download="downloadFile"
              />
            </div>
            <Uploader
              v-if="canEdit"
              reference-id="uploadOther"
              :clear-after="true"
              :limit="0"
              :path-to-upload="'/v1/application/' + entry.procedure.code + '/files/other'"
              @upload="updateFilesCart"
            />
          </div>
        </div>
      </div>

      <div v-if="canSubmit || isDraft || canEdit">
        <hr />
        <b-field grouped>
          <b-field v-if="canSubmit && isDraft && canEdit" class="has-margin-bottom">
            <b-button type="is-primary" outlined @click="doSubmitApplication">
              {{ $t('actions.submit') }}
            </b-button>
          </b-field>
          <b-field expanded>
            <b-button
              v-if="!isSubmitted && !isCancelled && canEdit"
              type="is-text"
              :loading="loadingSave"
              @click="doSaveApplication"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-field>
          <b-field>
            <b-button v-if="canOpen" type="is-text" @click="doReopen">{{ $t('actions.reopen') }}</b-button>
          </b-field>
          <b-field>
            <b-button v-if="!canOpen && canEdit" class="has-text-dark" type="is-text" @click="doCancelApplication">
              {{ $t('actions.cancel') }}
            </b-button>
          </b-field>
        </b-field>
      </div>
    </template>

    <template #sidebar>
      <ContentLoader v-if="loading" type="sidebar" />
      <div v-else>
        <div class="has-margin-bottom">
          <div class="label is-marginless">{{ $t('status') }}</div>
          <div
            class="is-size-1 has-text-weight-bold"
            :class="{
              'has-text-primary': entry.application.status == 'submitted',
            }"
          >
            {{ getItemStatusDescription(entry.application.status) }}
          </div>
        </div>

        <div v-if="canEdit || canCancel || canOpen" class="label">{{ $t('operations') }}</div>

        <b-field v-if="isDraft" class="has-margin-bottom">
          <b-button
            expanded
            size="is-medium"
            :disabled="!canSubmit && canEdit"
            :type="{
              'is-outlined': !canSubmit && canEdit,
              'is-primary': canSubmit && canEdit,
            }"
            @click="doSubmitApplication"
          >
            {{ $t('actions.submit') }}
          </b-button>
        </b-field>

        <b-field v-if="canEdit || canCancel || canOpen" grouped class="has-margin-bottom has-margin-top">
          <b-field expanded>
            <b-button v-if="canOpen" type="is-black" expanded :loading="loadingSave" @click="doReopen">
              {{ $t('actions.reopen') }}
            </b-button>
            <b-button
              v-if="!isSubmitted && !isCancelled && canEdit"
              :type="{
                'is-outlined': canSubmit,
                'is-primary': !canSubmit,
              }"
              expanded
              :loading="loadingSave"
              @click="doSaveApplication"
            >
              {{ $t('actions.save') }}
            </b-button>
          </b-field>
          <b-field>
            <b-button outlined @click="doCancelApplication">{{ $t('actions.cancel') }}</b-button>
          </b-field>
        </b-field>

        <hr />

        <div>
          <h4 class="has-margin-bottom">{{ $t('support.needHelp') }}</h4>
          <button class="button is-small" @click="goToSupport">
            <faicon icon="concierge-bell"></faicon>
            {{ $t('support.ticket.new') }}
          </button>
        </div>

        <div class="has-margin-top-large">
          <div class="is-meta has-margin-bottom">{{ $t('procedure.details') }}</div>
          <div>
            <router-link :to="'/procedure/' + entry.procedure.code">
              <faicon icon="hashtag" class="has-text-small"></faicon>
              {{ entry.procedure.code }}
            </router-link>
          </div>
          <div class="has-margin-top has-margin-bottom">{{ procedureTitle }}</div>
          <div class="is-meta">
            <span v-if="entry.procedure.area">{{ entry.procedure.area }}</span>
          </div>
          <div class="is-meta has-margin-top">
            <span v-if="entry.procedure.department_title">{{ entry.procedure.department_title }} / </span>
            <span v-if="entry.procedure.faculty_title">{{ entry.procedure.faculty_title }}</span>
          </div>
        </div>
      </div>
    </template>

    <template #modals>
      <ModalAfterSubmit :show.sync="isAfterSubmitModalActive" />
      <ModalReadyToSubmit :show.sync="isReadyToSubmitModalActive" />
      <ModalDownloading :show.sync="isDownloadingFile" :percentage.sync="$store.state.isDownloading" />
    </template>
  </MainLayout>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { required, email, minLength, numeric } from 'vuelidate/lib/validators'
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import { Bold, Italic, Link, Underline, History, Placeholder } from 'tiptap-extensions'
import VueMonthlyPicker from 'vue-monthly-picker'

import ApplicationService from '@/services/ApplicationService'
import ProcedureService from '@/services/ProcedureService'

import MainLayout from '@/components/layouts/MainLayout'

import Uploader from '@/components/utilities/Uploader'
import ContentLoader from '@/components/utilities/ContentLoader'
import RecordFileEntry from '@/components/records/RecordFileEntry'
import ModalAfterSubmit from '@/components/modals/ModalAfterSubmit'
import ModalReadyToSubmit from '@/components/modals/ModalReadyToSubmit'
import ModalDownloading from '@/components/modals/ModalDownloading'
import CountriesSelect from '@/components/utilities/CountriesSelect'
import PhoneCountriesSelect from '@/components/utilities/PhoneCountriesSelect'

dayjs.extend(weekday)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Lisbon')

const CERTIFICATES = [
  'less_4_years',
  '4_years',
  '6_years',
  '9_years',
  '11_years',
  '12_years',
  'professional_course',
  'bachelor',
  'graduation',
  'master',
  'phd',
  'specialization_course',
  'qualification_ignored',
]
const CERTIFICATES_WITH_EXTRA_FIELDS = new Set([
  'bachelor',
  'graduation',
  'postgraduation',
  'master',
  'phd',
  'specialization_course',
])
const LEGAL_CATEGORIES = [
  'senior_technician',
  'tecnical_assistent',
  'tecnical_assistent_manager',
  'operational_assistant_supervisor',
  'operational_assistant_general',
  'operational_assistant',
  'computer_technician_n1',
  'computer_technician_n2',
  'computer_technician_n3',
  'computer_technician_g1n1',
  'computer_technician_g1n2',
  'computer_technician_g1n3',
  'computer_technician_g2n1',
  'computer_technician_g2n2',
  'computer_technician_g3n1',
  'computer_technician_g3n2',
  'computer_specialist_g1n1',
  'computer_specialist_g1n2',
  'computer_specialist_g1n3',
  'computer_specialist_g2n1',
  'computer_specialist_g2n2',
  'computer_specialist_g3n1',
  'computer_specialist_g3n2',
  'computer_specialist_intern_a',
  'computer_specialist_intern_b',
  'computer_technical_intern_a',
  'computer_technical_intern_b',
]
const LEGAL_APPOINTMENTS = ['definitive', 'limited', 'determinable']
const LEGAL_CONTRACTS = ['indefinite', 'certain', 'uncertain']
const LEGAL_CURRENT_SITUATION = ['in_office', 'license', 'sme', 'other']
const LEGAL_EVALUATION = ['no_requirements', 'not_attributable', 'yes']

export default {
  components: {
    VueMonthlyPicker,
    MainLayout,
    Uploader,
    ContentLoader,
    RecordFileEntry,
    EditorContent,
    EditorMenuBar,
    CountriesSelect,
    PhoneCountriesSelect,
    ModalAfterSubmit,
    ModalReadyToSubmit,
    ModalDownloading,
  },

  data() {
    return {
      CERTIFICATES: CERTIFICATES,
      CERTIFICATES_WITH_EXTRA_FIELDS: CERTIFICATES_WITH_EXTRA_FIELDS,
      LEGAL_CATEGORIES: LEGAL_CATEGORIES,
      LEGAL_APPOINTMENTS: LEGAL_APPOINTMENTS,
      LEGAL_CONTRACTS: LEGAL_CONTRACTS,
      LEGAL_CURRENT_SITUATION: LEGAL_CURRENT_SITUATION,
      LEGAL_EVALUATION: LEGAL_EVALUATION,

      loading: false,
      loadingSave: false,

      user: this.$store.getters.getUser,

      entry: {},

      // Editor para notas adicionais (opcional)
      editor: new Editor({
        editable: this.isDraft && this.canEdit,
        extensions: [
          new Link(),
          new Bold(),
          new Italic(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyNodeClass: 'is-empty',
            emptyNodeText: this.$t('writeText'),
            showOnlyWhenEditable: true,
          }),
        ],
        onUpdate: ({ getHTML }) => {
          this.entry.application.user_notes = getHTML()
        },
      }),

      isReadyToSubmitModalActive: false,
      isAfterSubmitModalActive: false,

      addStudiesActive: false,
      addTrainingActive: false,
      addExperienceActive: false,
      addOtherRolesActive: false,

      defaultData: {
        studiesEntry: {
          description: null,
          end_date: null,
          score: null,
          school: null,
          $errors: {},
        },
        trainingEntry: {
          description: null,
          start_date: null,
          end_date: null,
          score: null,
          company: null,
          hours: null,
          $errors: {},
        },
        experienceEntry: {
          description: null,
          start_date: null,
          end_date: null,
          company: null,
          $errors: {},
        },
        otherRoleEntry: {
          description: null,
          start_date: null,
          end_date: null,
          company: null,
          $errors: {},
        },
      },

      loadingNewItem: false,
      newStudiesEntry: null,
      newTrainingEntry: null,
      newExperienceEntry: null,
      newOtherRoleEntry: null,
    }
  },

  computed: {
    isDownloadingFile() {
      return this.$store.state.isDownloading > 0 && this.$store.state.isDownloading < 100
    },
    // Testes do estado da candidatura
    isEntryLoaded() {
      return this.entry && this.entry.application
    },
    procedureCode() {
      return this.$route.params.procedureKey
    },
    applicationStatus() {
      return ApplicationService.helpers.getStatusMap(this.entry.application.status)
    },
    procedureTitle() {
      return ProcedureService.helpers.getProcedureTitle(this.entry.procedure)
    },
    isDraft() {
      return this.entry && this.entry.application && this.entry.application.status == 'draft'
    },
    isSubmitted() {
      return this.entry && this.entry.application && this.entry.application.status == 'submitted'
    },
    isCancelled() {
      return this.entry && this.entry.application && this.entry.application.status == 'cancelled'
    },
    canEdit() {
      return this.entry && this.entry.permissions && this.entry.permissions.edit
    },
    canSubmit() {
      return (
        this.isEntryLoaded &&
        this.entry.permissions &&
        this.entry.permissions.submit &&
        this.isSectionReady('s-personal-data') &&
        this.isSectionReady('s-cv') &&
        this.isSectionReady('s-studies') &&
        this.isSectionReady('s-training') &&
        this.isSectionReady('s-experience') &&
        this.isSectionReady('s-other-roles') &&
        this.isSectionReady('s-public-employment') &&
        this.isSectionReady('s-acceptance') &&
        this.isSectionReady('s-handicap') &&
        this.isSectionReady('s-additional-notes')
      )
    },
    canCancel() {
      return this.entry.permissions.change_status
    },
    canOpen() {
      return (
        this.entry &&
        this.entry.application &&
        this.entry.application.status != 'draft' &&
        this.entry.permissions.change_status
      )
    },
    withProfessionalQualifications() {
      return (
        this.entry.application &&
        this.entry.application.professional_qualifications &&
        this.entry.application.professional_qualifications.length
      )
    },
    withCertificates() {
      return this.entry.application && this.entry.application.certificates && this.entry.application.certificates.length
    },
    withProfessionalExperiences() {
      return (
        this.entry.application &&
        this.entry.application.professional_experiences &&
        this.entry.application.professional_experiences.length
      )
    },
    withOtherActivitiesPerformed() {
      return (
        this.entry.application &&
        this.entry.application.other_activities_performed &&
        this.entry.application.other_activities_performed.length
      )
    },
  },

  watch: {
    'entry.application.legal_appointment'(newValue) {
      if (newValue) {
        this.entry.application.legal_contract = null
        this.entry.application.legal_current_situation = null
      }
    },
    'entry.application.legal_contract'(newValue) {
      if (newValue) {
        this.entry.application.legal_appointment = null
        this.entry.application.legal_current_situation = null
      }
    },
    'entry.application.legal_current_situation'(newValue) {
      if (newValue) {
        this.entry.application.legal_appointment = null
        this.entry.application.legal_contract = null
      }
    },
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  beforeMount() {
    this.getApplication()
  },

  created() {
    // Get defaults
    this.newStudiesEntry = this.getDefaultEntry('studiesEntry')
    this.newTrainingEntry = this.getDefaultEntry('trainingEntry')
    this.newExperienceEntry = this.getDefaultEntry('experienceEntry')
    this.newOtherRoleEntry = this.getDefaultEntry('otherRoleEntry')
  },

  // Valida o formulário de dados pessoais
  validations: {
    entry: {
      application: {
        name: {
          required,
          min: minLength(2),
        },
        birthday: {
          required,
        },
        address_line1: {
          required,
          min: minLength(2),
        },
        postal_code: {
          required,
        },
        locality: {
          required,
        },
        country: {
          required,
        },
        phone_country: {
          required,
        },
        phone_number: {
          required,
          numeric,
        },
        email: {
          required,
          email,
        },
        nif: {
          required,
        },
      },
    },
  },

  methods: {
    getDefaultEntry(key) {
      return Object.assign({}, this.defaultData[key])
    },
    isSectionReady(sectionId) {
      if (isEmpty(this.entry)) return false

      if (sectionId == 's-personal-data') {
        return Boolean(
          this.entry.application.name &&
            this.entry.application.birthday &&
            this.entry.application.address_line1 &&
            this.entry.application.locality &&
            this.entry.application.postal_code &&
            this.entry.application.phone_number &&
            this.entry.application.email &&
            this.entry.application.nif &&
            !isEmpty(this.entry.application.phone_country) &&
            !isEmpty(this.entry.application.country)
        )
      } else if (sectionId == 's-cv') {
        const files = this.getFilesByType('cv')
        return files && files.length == 1
      } else if (sectionId == 's-acceptance') {
        return (
          this.entry.application.acceptance_documents_authenticity &&
          this.entry.application.acceptance_requirements &&
          this.entry.application.accept_notifications
        )
      } else if (sectionId == 's-handicap') {
        return (
          !this.entry.application.is_handycap ||
          (this.entry.application.is_handycap && this.getFilesByType('handicap').length)
        )
      } else if (sectionId == 's-studies') {
        return this.withCertificates && this.getFilesByType('certificate').length
      } else if (sectionId == 's-training') {
        return true
      } else if (sectionId == 's-experience') {
        return true
      } else if (sectionId == 's-other-roles') {
        return true
      } else if (sectionId == 's-public-employment') {
        return (
          !this.entry.application.with_legal_functional ||
          (this.entry.application.with_legal_functional &&
            this.entry.application.legal_company &&
            this.entry.application.legal_category &&
            (this.entry.application.legal_appointment ||
              this.entry.application.legal_contract ||
              this.entry.application.legal_current_situation) &&
            this.entry.application.legal_activity &&
            this.entry.application.legal_evaluation &&
            (this.entry.application.legal_is_uc_worker || this.getFilesByType('legal_functional').length))
        )
      } else if (sectionId == 's-additional-notes') {
        return true
      }

      return false
    },

    getItemStatusDescription(status) {
      const map = {
        draft: this.$t('draft'),
        cancelled: this.$t('cancelled'),
        submitted: this.$t('submitted'),
      }
      return map[status]
    },
    dateFormatter(date) {
      return dayjs(date).format('YYYY-MM-DD')
    },

    updateCountry(value) {
      this.entry.application.country = value
    },

    updatePhoneCountry(value) {
      this.entry.application.phone_country = value
    },

    // Obtém o ojecto da candidatura
    async getApplication(silent = false) {
      this.loading = !silent

      if (this.$route.params.action == 'start') {
        this.entry = await ApplicationService.add(this.$route.params.procedureKey)
        this.$router.replace({ ...this.$router.currentRoute, params: { action: 'view' } })
      }

      this.entry = await ApplicationService.get(this.$route.params.procedureKey)

      // Popula o nome com os dados do perfil e define o país default como Portugal
      if (!this.entry.application.name) {
        this.entry.application.name = this.user.full_name || ''
      }
      if (this.entry.application.birthday) {
        this.entry.application.birthday = dayjs(this.entry.application.birthday).toDate()
      }

      if (!this.entry.application.public_employment) {
        this.entry.application.public_employment = false
      }

      if (!this.entry.application.email) this.entry.application.email = this.user.email

      // Converte das datas
      // if (this.entry.application.identity_expire_date) {
      //   this.identityExpireDate = dayjs(this.entry.application.identity_expire_date).toDate()
      // }

      // Popula os editores de texto com o conteúdos definido pelo utilizador
      this.editor.setContent(this.entry.application.user_notes)

      this.loading = false
    },

    // Envia a candidatura para o backend e utiliza o novo objeto no dom
    doSaveApplication(silenceNotification = false) {
      this.loadingSave = true

      this.$v.$touch()

      // Because of Timezone problems on axios, send just the date string to the server
      let updatedAplication = JSON.parse(JSON.stringify(this.entry.application))
      updatedAplication.birthday = this.dateFormatter(updatedAplication.birthday)

      ApplicationService.update(updatedAplication)
        .then(response => {
          const entry = response.data
          if (entry.application.birthday) {
            entry.application.birthday = dayjs(entry.application.birthday).toDate()
          }

          this.entry = entry
          this.loadingSave = false

          if (!silenceNotification) {
            this.$buefy.snackbar.open({
              message: this.$t('applicationState.saved'),
              type: 'is-primary',
              position: 'is-bottom-right',
              duration: 2000,
              queue: false,
            })
          }
        })
        .catch(err => {
          this.alertErrorCallEnded(err.response.status)
        })
    },

    doCancelApplication() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.cancel'),
        message: this.$t('applicationState.cancelConfirm'),
        confirmText: this.$t('applicationState.cancelYes'),
        cancelText: this.$t('applicationState.cancelNo'),
        onConfirm: async () => {
          this.entry.application.status = 'cancelled'

          ApplicationService.update(this.entry.application)
            .then(response => {
              this.entry = response.data
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    doReopen() {
      this.$buefy.dialog.confirm({
        title: this.$t('applicationState.reopen'),
        message: this.$t('applicationState.reopenConfirm'),
        confirmText: this.$t('actions.reopen'),
        type: 'is-black',
        onConfirm: async () => {
          this.entry.application.status = 'draft'

          ApplicationService.update(this.entry.application)
            .then(response => {
              this.entry = response.data
              this.$buefy.notification.open({
                type: 'is-black',
                message: this.$t('applicationState.reopenDone'),
                duration: 2000,
              })
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    doSubmitApplication() {
      this.$buefy.dialog.confirm({
        size: 'is-large',
        cancelText: this.$t('actions.cancel'),
        title: this.$t('applicationState.submit'),
        message: this.$t('applicationState.submitConfirm'),
        onConfirm: async () => {
          // Cria novo objeto para garantir que é apenas considerado se válido no backend
          const updatedApplication = cloneDeep(this.entry.application)
          updatedApplication.status = 'submitted'
          updatedApplication.birthday = this.dateFormatter(updatedApplication.birthday)

          ApplicationService.update(updatedApplication)
            .then(response => {
              this.entry = response.data
              this.showAfterSubmitModal()
            })
            .catch(err => {
              this.alertErrorCallEnded(err.response.status)
            })
        },
      })
    },

    async updateSwitch() {
      this.doSaveApplication(true)
    },

    async removeListEntry(entryKey, listKey) {
      const newItems = await ApplicationService.deleteListEntry(this.entry.application.code, listKey, entryKey)
      this.entry.application[listKey] = newItems
    },

    async addToList(type, attribute, formBoxActive = null) {
      this.loadingNewItem = true

      try {
        const entry = { ...this[attribute] }

        // Parse dates
        if (entry.start_date) entry.start_date = this.dateFormatter(entry.start_date)
        if (entry.end_date) entry.end_date = this.dateFormatter(entry.end_date)

        // Save, get fresh data and reset
        const { items, errors } = await ApplicationService.addListEntry(this.entry.application.code, type, entry)

        if (errors) {
          const newErrors = {}
          for (const errorItem of errors) newErrors[errorItem.field] = errorItem.message
          this[attribute].$errors = newErrors
        } else {
          if (formBoxActive) this[formBoxActive] = false
          this.entry.application[type] = items
          this[attribute] = this.getDefaultEntry('studiesEntry')
        }
      } finally {
        this.loadingNewItem = false
      }
    },
    async addStudies() {
      await this.addToList('certificates', 'newStudiesEntry', 'addStudiesActive')
    },
    async addTraining() {
      await this.addToList('professional_qualifications', 'newTrainingEntry', 'addTrainingActive')
    },
    async addExperience() {
      await this.addToList('professional_experiences', 'newExperienceEntry', 'addExperienceActive')
    },
    async addOtherRole() {
      await this.addToList('other_activities_performed', 'newOtherRoleEntry', 'addOtherRolesActive')
    },

    // Gestão de ficheiros
    async downloadFile(file) {
      await ApplicationService.downloadFile(file.key, file.filename)
    },

    removeFile(file) {
      this.$buefy.dialog.confirm({
        message: this.$t('deleteFileConfirm'),
        type: 'is-danger',
        onConfirm: async () => {
          const fileDeleted = await ApplicationService.deleteFile(file.key)
          if (fileDeleted) {
            this.doSaveApplication(true)
          }
        },
      })
    },

    updateFilesCart() {
      this.doSaveApplication(true)
    },

    getFilesByType(type) {
      if (!this.entry.application || !this.entry.application.files) {
        return []
      }
      return this.entry.application.files.filter(file => {
        return file.type == type
      })
    },

    goToSupport() {
      if (this.isDraft) {
        this.$buefy.dialog.confirm({
          title: this.$t('saveBeforeLeave'),
          message: this.$t('saveBeforeTicket'),
          cancelText: this.$t('actions.cancel'),
          confirmText: this.$t('saveLeave'),
          type: 'is-primary',
          onConfirm: () => {
            this.doSaveApplication(true)
            this.$router.push({ path: '/support', query: { procedureCode: this.entry.procedure.code, openNew: true } })
          },
        })
      } else {
        this.$router.push({ path: '/support', query: { procedureCode: this.entry.procedure.code, openNew: true } })
      }
    },

    // Modais
    showAfterSubmitModal() {
      this.isAfterSubmitModalActive = true
    },
    alertErrorCallEnded(status) {
      // Em caso de erro, obter novamente a candidatura do lado da api
      this.getApplication()

      if (status == 403) {
        this.$buefy.dialog.alert({
          title: this.$t('applicationState.closed'),
          message: this.$t('applicationState.closedDescription'),
          type: 'is-black',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      }

      // Ocorreu algo inesperado
      else {
        this.$buefy.notification.open({
          type: 'is-danger',
          message: this.$t('applicationState.internalError'),
          duration: 5000,
        })
      }
    },
  },
}
</script>
